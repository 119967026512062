import React from 'react'
import { Container, Grid } from '@mui/material'
import { Navigation } from './Navigation'
import { Link } from 'react-router-dom'

import './Header.css'

export const Header = () => {
	return (
		<Container>
			<Grid container>
				<Grid item xs={4}>
					<header>
						<h1>
							<Link to="/" title="Pittville Badminton Club">
								<img src="https://pittvillebadminton.co.uk/images/pittvillebadminton/logo.png" alt="Pittville Badminton Club" title="Pittville Badminton Club" />
								{/* class="img-responsive" /> */}
							</Link>
						</h1>
					</header>
				</Grid>
				<Grid item xs={8}>
					<Navigation />
				</Grid>
			</Grid>
		</Container>
	)
}
