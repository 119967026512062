import React from 'react'

import './Box.css'

export const Box = ({ header, children }: { header?: string, children: React.ReactNode }) => {
    return (
        <>
            {header && <h2>{header}</h2>}
            <div className="box">
                {children}
            </div>
        </>
    )
}
