import React from 'react'
import { Alert } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { Box } from '../components/Box'
import AddIcon from '@mui/icons-material/Add'
import LoadingButton from '@mui/lab/LoadingButton'
import { API } from 'aws-amplify';
import { useMutation } from '@tanstack/react-query';

export const EventWaitingListForm = ({ event, registration }: { event: any, registration: any }) => {
	const waitingListMutation = useMutation<any, Error, { message: string }>((input) => {
		return API.post('pittville', '/event/' + event.slug + '/player/' + registration.id + '/waitlist', {
			body: {
				message: input.message
			}
		})
	})

	const registrationInfo = registration.registration_info ? JSON.parse(registration.registration_info) : null

	return (<>
		<Box header={registration ? registration.name + " is on the Waiting List" : "Add " + registration.name + " onto Waiting List"}>
			<p>Sorry, {registration.name} hasn't currently been invited to sign up to this event.</p>
			<p>
				You can use the following form to add them onto the waiting list and request
				for them to be invited to sign up for this event.
			</p>

			<Formik
				initialValues={{
					message: (registrationInfo && registrationInfo['waiting-list-message']) || '',
				}}
				validate={(values) => {
					const errors: { [key: string]: string } = {}
					if (values.message && values.message.length > 100)
						errors.message = 'Message is too long'
					return errors
				}}
				onSubmit={async (values, { setSubmitting }) => {
					console.log('values', values)
					try {
						await waitingListMutation.mutateAsync(values)
					} catch (error) {
						console.error(error)
					}
					// Don't seem to need the following... why? is it because we've passed an async to onSubmit?
					// setSubmitting(false)
				}}
			>
				{({ submitForm, isSubmitting }) => (
					<Form>
						{waitingListMutation.error && <Alert severity="error">{waitingListMutation.error.message}</Alert>}
						<p><Field
							component={TextField}
							name="message"
							label="Message"
							size="small"
							multiline
							rows={2}
							fullWidth
							// disabled
						/></p>
						<p>
						<LoadingButton
							type="submit"
							variant="contained"
							startIcon={<AddIcon />}
							loading={isSubmitting}
							loadingPosition="start"
						>
							{registrationInfo ? 'Update Waiting List Request' : 'Add to Waiting List'}							
						</LoadingButton>
						</p>
					</Form>
				)}
			</Formik>
		</Box>
	</>)
}
