import React from 'react'
import { useAuthenticator } from '@aws-amplify/ui-react';

import './Navigation.css'

export const Navigation = () => {
	const { authStatus, signOut } = useAuthenticator((context) => [context.authStatus]);

	return (
		<nav>
			<ul>
				{/* <li><a href="/"><img src="https://pittvillebadminton.co.uk/images/pittvillebadminton/ShuttleBulletSmall.png" alt="Home" /> Home</a></li>
				<li><a href="/about"><img src="https://pittvillebadminton.co.uk/images/pittvillebadminton/ShuttleBulletSmall.png" alt="About Us" /> About Us</a></li>
				<li><a href="/docs"><img src="https://pittvillebadminton.co.uk/images/pittvillebadminton/ShuttleBulletSmall.png" alt="Docs" /> Docs</a></li>
				<li><a href="/venues"><img src="https://pittvillebadminton.co.uk/images/pittvillebadminton/ShuttleBulletSmall.png" alt="Venues" /> Venues</a></li>
				<li><a href="/sessions"><img src="https://pittvillebadminton.co.uk/images/pittvillebadminton/ShuttleBulletSmall.png" alt="Sessions" /> Sessions</a></li>
				<li><a href="/events"><img src="https://pittvillebadminton.co.uk/images/pittvillebadminton/ShuttleBulletSmall.png" alt="Events" /> Events</a></li> */}

				{authStatus === 'authenticated' && <li><button onClick={signOut}><img src="https://pittvillebadminton.co.uk/images/pittvillebadminton/ShuttleBulletSmall.png" alt="Logout" /> Logout</button></li>}
			</ul>
		</nav>
	)
}
