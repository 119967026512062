import React from 'react'
import { Chip, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { Link } from 'react-router-dom'

import BadmintonEmoji from '../badminton.png'

export const EventList = ({ events }: { events: any[] }) => {
	return (
		<List>
			{events.map(event => (
				<React.Fragment key={'event-' + event.id}>
					<ListItemButton component={Link} to={event.slug}>
						<ListItemIcon>
							<img src="https://pittvillebadminton.co.uk/images/pittvillebadminton/ShuttleBulletSmall.png" alt="Pittville Shuttle Logo" className="icon" />
						</ListItemIcon>
						<ListItemText primary={event.long_name} />
					</ListItemButton>

					{event.registrations && (
						<List disablePadding>
							{event.registrations.map((registration: { id: string; player_id: string; name: string, status: string }) => (
								<ListItemButton key={'reg-' + registration.id} sx={{ pl: 9 }} component={Link} to={event.slug + '/' + registration.player_id}>
									<ListItemIcon>
										<img src={BadmintonEmoji} alt="Badminton Emoji" className="icon" />
									</ListItemIcon>
									<ListItemText primary={registration.name} />
									<Chip label={registration.status} variant="outlined" />
								</ListItemButton>
							))}
						</List>
					)}
				</React.Fragment>
			))}
		</List>
	)
}
