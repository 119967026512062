import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { API } from 'aws-amplify'
import { useEvent } from '../pages/EventPage'
import { Alert, Button, CircularProgress } from '@mui/material'
import { Box } from './Box'
import { PlayerList } from './PlayerList'
import { Link } from 'react-router-dom'

export const PlayerSelectionList = () => {
	const { event } = useEvent()
	const playersQ = useQuery<any, Error>(['players', event.slug], async () => {
		const response = await API.get('pittville', '/event/' + event.slug + '/players', {})
		return response
	})
	console.log('event', event, 'eventPlayers', playersQ.data)

	return (<>
		{playersQ.isLoading ? <CircularProgress /> : (
			playersQ.isError ? <Alert severity="error"><>{playersQ.error.message}</></Alert> : (<>
				{playersQ.data.registered && (
					<Box header="Players Already Signed Up">
						<PlayerList players={playersQ.data.registered} />
					</Box>
				)}
				{playersQ.data.payg && (
					<Box header="Players Attending on a PAYG basis">
						<PlayerList players={playersQ.data.payg} />
					</Box>
				)}
				{playersQ.data.invited && (
					<Box header="Players Invited to Sign Up">
						<PlayerList players={playersQ.data.invited} />
					</Box>
				)}
				{playersQ.data.waiting && (
					<Box header="Players on Waiting List">
						<PlayerList players={playersQ.data.waiting} />
					</Box>
				)}
				{playersQ.data.null && (
					<Box header="Your Other Players">
						<PlayerList players={playersQ.data.null} />
					</Box>
				)}
				<Button component={Link} to="new" variant="contained">
					Signup New Player
				</Button>
			</>)
		)}
	</>)
}
