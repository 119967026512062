import React from 'react'
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { Link } from 'react-router-dom'

import BadmintonEmoji from '../badminton.png'

export const PlayerList = ({ players, hrefPrefix }: { hrefPrefix?: string, players: { id: string, name: string, status: string }[] }) => {
	return (
		<List>
			{players.map(player => (
				<ListItemButton key={player.id} component={Link} to={(hrefPrefix ? hrefPrefix + '/' : '') + player.id}>
					<ListItemIcon>
						<img src={BadmintonEmoji} alt="Badminton Emoji" className="icon" />
					</ListItemIcon>
					<ListItemText primary={player.name} />
				</ListItemButton>
			))}
		</List>
	)
}
