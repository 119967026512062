import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { API } from 'aws-amplify'
import { Link, useParams } from 'react-router-dom'
import { Alert, Button, CircularProgress } from '@mui/material'
import { useEvent } from './EventPage'
import { EventWaitingListForm } from '../forms/EventWaitingListForm'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

const renderForm = (event: any, registration: any) => {
	if (!registration || !registration.status) {
		return <EventWaitingListForm event={event} registration={registration} />
	}

	switch (registration.status) {
		case 'registered':
		case 'payg':
			// RSVP and individual session signup
			break;
		case 'invited':
			// Register
			break;
		case 'waiting':
			return <EventWaitingListForm event={event} registration={registration} />
		default:
			return <Alert severity="error">Unknown registration status: {registration.status}</Alert>
	}
}

export const EventRegistrationPage = () => {
	const { eventSlug, playerId } = useParams()
	const { event } = useEvent()
	console.log('eventSlug', eventSlug, 'event', event, 'player', playerId)

	const regQ = useQuery<any, Error>(['registration', eventSlug, playerId], async () => {
		const response = await API.get('pittville', '/event/' + eventSlug + '/player/' + playerId, {})
		return response
	})
	const registration = regQ.data
	console.log('registration', registration)

	return (<>
		<p>
			<Button component={Link} to=".." variant="contained" color="secondary" startIcon={<ArrowBackIcon />}>
				Back to Player Selection
			</Button>
		</p>
		{regQ.isLoading ? <CircularProgress /> : (
			regQ.isError ? <Alert severity="error"><>{regQ.error.message}</></Alert> : renderForm(event, registration)
		)}
	</>)
}
