import React from 'react'
import { Header } from './components/Header'
import { AppRouter } from './app/router'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Container, createTheme, Theme, ThemeProvider } from '@mui/material'
import { Authenticator } from '@aws-amplify/ui-react'
import { BrowserRouter } from 'react-router-dom'

import './App.css'

const queryClient = new QueryClient()

const theme: Theme = createTheme({
	palette: {
		primary: {
			main: '#be0000',
		},
		secondary: {
			main: '#e0e0e0',
		}
	}
})

function App() {
	return (
		<QueryClientProvider client={queryClient}>
			<ThemeProvider theme={theme}>
				<Authenticator.Provider>
					<BrowserRouter>
						<div className="app">
							<Header />
							<Container>
								<Authenticator>
									<AppRouter />
								</Authenticator>
							</Container>
							{/* <a target="_blank" rel="noopener noreferrer">Learn React</a> */}
						</div>
					</BrowserRouter>
				</Authenticator.Provider>
			</ThemeProvider>
		</QueryClientProvider>
	)
}

export default App
