import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'

import awsExports from './aws-exports'
import { Amplify, Auth } from 'aws-amplify'

import '@aws-amplify/ui-react/styles.css'
import './index.css'

Amplify.configure(awsExports)
Amplify.configure({
	API: {
		endpoints: [{
			name: 'pittville',
			endpoint: 'https://qw7a5clhzi.execute-api.eu-west-1.amazonaws.com',
			custom_header: async () => {
				return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
			}
		}]
	}
})

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
)

root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
