import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { API } from 'aws-amplify'
import { Outlet, useOutletContext, useParams } from 'react-router-dom'
import { Alert, CircularProgress } from '@mui/material'
import { Box } from '../components/Box'

interface Event {
	id: number
	type: string
	slug: string
	short_name: string
	medium_name: string
	long_name: string
	description: string
	open: string
	last_session: string
	taken_places: number
	max_places: number | undefined
}

interface OutletContext {
	event: Event
}

export const EventPage = () => {
	const { eventSlug } = useParams()
	const eventQ = useQuery<Event, Error>(['event', eventSlug], async () => {
		const response = await API.get('pittville', '/event/' + eventSlug, {})
		return response
	})
	console.log('event', eventQ.data)

	return (<>
		{eventQ.isLoading ? <CircularProgress /> : (
			eventQ.isError ? <Alert severity="error"><>{eventQ.error.message}</></Alert> : (<>
				<Box header={eventQ.data.long_name}>
					<div dangerouslySetInnerHTML={{__html: eventQ.data.description}} />
				</Box>
				<Outlet context={{ event: eventQ.data }} />
			</>)
		)}
	</>)
}

export const useEvent = () => {
	return useOutletContext<OutletContext>()
}
