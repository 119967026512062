import React from 'react'
import { Alert, CircularProgress } from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { API } from "aws-amplify"
import { Box } from "../components/Box"
import { EventList } from "../components/EventList"

export const Home = () => {
	const currentEventsQ = useQuery<any, Error>(['currentEvents'], async () => {
		try {
			const response = await API.get('pittville', '/current', {})
			console.log('response', response)
			return response
		} catch (e) {
			console.log('error', e)
			throw e
		}
	})

	// useEffect(() => {
	// 	const test = async () => {
	// 		const session = await Auth.currentSession()
	// 		const username = session.getIdToken().payload['cognito:username']
	// 		const groups = session.getIdToken().payload['cognito:groups']
	// 		console.log('session', session)
	// 		console.log('username', username)
	// 		console.log('groups', groups)
	// 	}
	// 	test()
	// }, [])

	return (
		<>
		<Box header="Home">
			{currentEventsQ.isLoading ? <CircularProgress /> : (
				currentEventsQ.isError ? <Alert severity="error"><>{currentEventsQ.error.message}</></Alert> : (
					<EventList events={currentEventsQ.data} />
				)
			)}
		</Box>
		</>
	)
}
