import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { PlayerSelectionList } from '../components/PlayerSelectionList'
import { EventPage } from '../pages/EventPage'
import { EventRegistrationPage } from '../pages/EventRegistrationPage'
import { Home } from '../pages/Home'
import { PageNotFound } from '../pages/PageNotFound'

export const AppRouter = () => {
	return (
		<Routes>
			<Route index element={<Home />} />
			<Route path=":eventSlug" element={<EventPage />}>
				<Route index element={<PlayerSelectionList />} />
				<Route path=":playerId" element={<EventRegistrationPage />} />
				<Route path="new" element={<p>NEW PLAYER</p>} />
			</Route>
			<Route path="*" element={<PageNotFound />} />
		</Routes>
	)
}
